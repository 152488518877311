import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {
  return (
    <header className="hero">
        <div className="logo">Blue Ozark Development</div>      
        <div className="hero-content">
          <h1>Innovative Development Solutions</h1>
          <p>Navigate the stars to find what you need!</p>
          {/* <a href="#contact" className="cta-button">Get in Touch</a> */}
        </div>
    </header>
  );
}

export default Header;
