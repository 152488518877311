import { useState, useEffect } from "react";

const useItems = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        // Fetch project and resource lists
        const [projectsResponse, resourcesResponse] = await Promise.all([
          fetch('/projects.json'),
          fetch('/resources.json')
        ]);

        const projectFiles = await projectsResponse.json();
        const resourceFiles = await resourcesResponse.json();

        // Fetch project markdown files
        const projectItems = await Promise.all(
          projectFiles.map(async (filename) => {
            const response = await fetch(`/content/projects/${filename}`);
            const content = await response.text();
            
            // Parse front matter (metadata) from markdown
            const metadata = parseFrontMatter(content);
            return {
              ...metadata,
              key: filename.replace('.md', ''),
              category: 'project',
              content
            };
          })
        );

        // Fetch resource markdown files
        const resourceItems = await Promise.all(
          resourceFiles.map(async (filename) => {
            const response = await fetch(`/content/resources/${filename}`);
            const content = await response.text();
            
            // Parse front matter (metadata) from markdown
            const metadata = parseFrontMatter(content);
            return {
              ...metadata,
              key: filename.replace('.md', ''),
              category: 'resource',
              content
            };
          })
        );

        setItems([...projectItems, ...resourceItems]);
      } catch (error) {
        console.error("Error fetching items:", error);
      }
    };

    fetchItems();
  }, []);

  return items;
};

// Simple front matter parser
const parseFrontMatter = (content) => {
  const frontMatterRegex = /^---\n([\s\S]*?)\n---/;
  const match = content.match(frontMatterRegex);
  
  if (!match) return {};
  
  const frontMatter = match[1];
  const metadata = {};
  
  frontMatter.split('\n').forEach(line => {
    const [key, ...valueParts] = line.split(':');
    if (key && valueParts.length) {
      metadata[key.trim()] = valueParts.join(':').trim();
    }
  });
  
  return metadata;
};

export default useItems;